"use client"
import { sendBacon } from "@local/utils/src/sendBeacon"
import { objectToShiftedBase64 } from "@local/utils/src/safeBase64"
import { baseParametersForTracking, trackAction } from "./trackActions"
import { isBrowser } from "@local/utils/src/isBrowser"
import Cookies from "js-cookie"
import { getTrackingCookies } from "./getTrackingCookies"

// https://developers.google.com/tag-platform/tag-manager/web/datalayer
// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#data-layer

const digestMessage = async (val?: string | null) => {
  if (!val) {
    return null
  }

  const msgUint8 = new TextEncoder().encode(val) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("") // convert bytes to hex string
  return hashHex
}

interface TikTokIdentifyInput {
  email?: string | null
  phone?: string | null
}

export const identifyTtq = async ({ email, phone }: TikTokIdentifyInput) => {
  const userId = Cookies.get("gtm_upi")
  let hashedEmail = email
  let hashedPhone = phone

  try {
    if (isBrowser() && window.ttq) {
      /**
       * Hello, welcome to callback hell. This is those chains of promises that either we make it suck like this
       * Or we refactor half an application just to accomodate tracking on TikTok. So... There we go...
       * All of this because TikTok wants us to SHA256 all PII, but ironiaclly, we all know they don't care about
       * privacy. Well well well...
       */
      digestMessage(email).then((res) => {
        if (res) {
          hashedEmail = res
        }
        digestMessage(phone).then((res) => {
          if (res) {
            hashedPhone = res
          }
          digestMessage(userId).then((res) => {
            if (res) {
              window.ttq.identify({
                email: hashedEmail, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
                phone_number: hashedPhone, // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
                external_id: res, // A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
              })
            }
          })
        })
      })
    }
  } catch (e) {
    console.error("ttq not loaded")
  }
}

interface IdentifyUserArgs {
  email?: string | null
  specific?: "exp" | "nonexp" | "tiktok"
  phone?: string | null
}

export const identifyUser = ({ email, phone, specific }: IdentifyUserArgs) => {
  try {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    const PhoneRegex = "^\\+[1-9]\\d{1,14}$"
    let emailClean = null
    let phoneClean = null
    const trackingStuff = getTrackingCookies()

    if (email) {
      emailClean = email.toLowerCase().trim()

      if (!emailClean.match(emailRegex)) {
        console.error("Invalid email")
        emailClean = null
      }
    }

    if (phone) {
      phoneClean = phone.trim()

      if (!phoneClean.match(PhoneRegex)) {
        console.error("Invalid phone")
        phoneClean = null
      }
    }

    if (emailClean) {
      trackAction("consent", {
        action: "accept",
        category: "promotional",
        valid_until: "unlimited",
        identification_type: "cookie",
        email: emailClean,
        identification: emailClean,
      })
      if (
        window.exponea &&
        window.exponea.track &&
        (specific === "exp" || !specific)
      ) {
        window.exponea.identify({ email_ID: emailClean })
      }
      // ref: https://support.google.com/google-ads/answer/13258081?#Add_a_code_snippet&zippy=identify-and-define-your-enhanced-conversions-fields
      if (window.gtag) {
        window.gtag("set", "user_data", {
          email: emailClean,
        })
      }
    }

    if (phoneClean) {
      trackAction("consent", {
        action: "accept",
        category: "promo-sms",
        identification_type: "application",
        identification: phone,
        valid_until: "unlimited",
      })
      if (window.exponea && (specific === "exp" || !specific)) {
        window.exponea.identify({
          customer_phone: phone,
        })
      }
      // ref: https://support.google.com/google-ads/answer/13258081?#Add_a_code_snippet&zippy=identify-and-define-your-enhanced-conversions-fields
      if (window.gtag) {
        window.gtag("set", "user_data", {
          phone_number: phoneClean,
        })
      }
    }

    const endpointDomain = `${process.env.NEXT_PUBLIC_APP_URL}/api`

    sendEvent: {
      if (!specific || specific === "nonexp") {
        const endpoint = `${endpointDomain}/t/`
        const body = {
          channelId:
            window.channel ?? (process.env.NEXT_PUBLIC_CHANNEL_ID as string),
          data: {
            email: emailClean,
            phone: phoneClean,
          },
          name: "identify",
          user: baseParametersForTracking(),
        }

        const encodedBody = objectToShiftedBase64(body, 24)
        const blob = new Blob([encodedBody], {
          type: "text/plain",
        })

        sendBacon(endpoint, blob)
      }
    }

    identifyFlow: {
      const url = new URL(window.location.href)
      const params = url.searchParams

      const endpoint = `${endpointDomain}/i/`
      const body = {
        c: trackingStuff.chills,
        email: emailClean,
        l: url.href,
        phone: phoneClean,
        r: document && document.referrer ? document.referrer : null,
        rc: params.has("r_sub_id") ? params.get("r_sub_id") : null,
        v: trackingStuff.vibes,
      }

      const encodedBody = objectToShiftedBase64(body, 24)
      const blob = new Blob([encodedBody], {
        type: "text/plain",
      })

      sendBacon(endpoint, blob)
    }

    tiktok: {
      if (!specific || specific === "tiktok") {
        identifyTtq({ email: emailClean, phone: phoneClean })
      }
    }
  } catch (e) {
    console.error("ttq not loaded")
  }
}
