import { CountryFlagData } from "@local/i10n"
import { DropDown } from "@local/ui/src/DropDown"
import { flagCodeToPng } from "@local/utils/src/flagCodeToPng"
import { useEffect, useState } from "react"

interface FlagDropdownProps {
  defaultFlag?: string
  onSelect: (code: string) => void
  label?: string
}

export default function FlagDropdown({
  defaultFlag,
  onSelect,
  label,
}: FlagDropdownProps) {
  const [selected, setSelected] = useState(defaultFlag?.toUpperCase() ?? "US")

  useEffect(() => {
    if (defaultFlag?.toUpperCase() === selected) return
    setSelected(defaultFlag?.toUpperCase() ?? "US")
  }, [defaultFlag, selected])

  const flagFromCode = () =>
    flagCodeToPng(CountryFlagData.find((c) => c.code === selected)?.code ?? "")

  const handleSelect = (code: string) => {
    setSelected(code)
    onSelect(code)
  }

  return (
    <>
      <label className="text-sm">{label || "Phone"}</label>
      <div className={"absolute"}>
        <DropDown
          selected={selected}
          onChange={handleSelect}
          options={CountryFlagData.map((flagData) => {
            return {
              outerLabel: flagFromCode(),
              label: `${flagData.name} +${flagData.phone}`,
              startIcon: flagCodeToPng(flagData.code),
              value: flagData.code,
            }
          })}
          id="international-phone-country-dropdown"
          className="h-full pr-8 bg-opacity-0 shadow-none"
          bigLabel
          dropdownClasses="min-h-[480px] max-h-[480px] overflow-y-auto w-[80vw] md:w-[480px]"
          wrapperClassName="py-1"
        />
      </div>
    </>
  )
}
