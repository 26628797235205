import { Controller } from "react-hook-form"
import { classNames } from "@local/utils"
import FlagDropdown from "./FlagDropdown"
import { useEffect, useState } from "react"
import { CountryFlagData } from "@local/i10n"
import Style from "./tooltip.module.css"
interface PhoneInputProps {
  id?: string
  country?: string
  setCountry: (code: string) => void
  control: any
  errors: any
  disabled?: boolean
  className?: string
  persistentPhone?: string
  label?: string
}

export const PhoneInput = ({
  country,
  setCountry,
  control,
  errors,
  disabled,
  className,
  label,
}: PhoneInputProps) => {
  const getPhoneMask = (code: string) => {
    const mask =
      CountryFlagData.find((c) => c.code === code)?.mask ??
      CountryFlagData[0].mask
    return mask instanceof Array ? mask[mask.length - 1] : mask
  }

  const [phoneMask, setPhoneMask] = useState(getPhoneMask(country ?? "us"))

  useEffect(() => {
    setPhoneMask(getPhoneMask(country ?? "us"))
  }, [country])

  return (
    <div className="w-full mt-2 sm:col-span-2">
      <div className="relative">
        <FlagDropdown
          defaultFlag={country}
          onSelect={(code) => {
            setCountry(code)
            setPhoneMask(getPhoneMask(code ?? "us"))
          }}
          label={label}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <>
              <input
                type="tel"
                id={field.name}
                aria-required
                aria-label="Phone number"
                autoComplete="tel-local"
                disabled={disabled}
                value={field.value}
                onChange={(e) => field.onChange(e)}
                className={classNames(
                  "z-20 block w-full border-gray-200 shadow-sm focus:border-zinc-500 focus:ring-zinc-500 sm:text-sm placeholder-gray-300 pl-16 tracking-normal py-3",
                  disabled ? "bg-gray-200" : "",
                  className ? className : ""
                )}
                placeholder={phoneMask}
              />
              {errors && errors["phone"] && errors["phone"] && (
                <p className="text-sm text-red-500">
                  {errors["phone"]?.message}
                </p>
              )}
              <div className="absolute right-2.5 top-[35px]">
                <div
                  className={Style.Tooltip}
                  data-text="In case we need to contact you about your order."
                >
                  <div
                    tabIndex={0}
                    className="flex items-center justify-center w-5 h-5 text-xs text-white bg-gray-400 rounded-full cursor-pointer"
                  >
                    <span>?</span>
                  </div>
                </div>
              </div>
            </>
          )}
        />
      </div>
    </div>
  )
}

export default PhoneInput
